<template>
    <!-- Cant Get this to draw correctly in the Card!!! -->
    <div class="rounded">
        <CCard class="p-0 m-0 rounded">
            <CCardBody class="p-0 m-0 rounded h-100 overflow-hidden">

                <GrafanaEmbeddedDashboard :src="urlPath" :from="from" :selected="selected" :to="to" @load="onLoaded">
                </GrafanaEmbeddedDashboard>
            </CCardBody>
        </CCard>
    </div>
</template>

<style>
.box-title {
    text-align: center;
    font-size: 16px;
    float: center;
    width: 80%;
}
</style>



<script>
import DashboardCard from "../dashboard/DashboardCard";
import RemovableDashboardCard from "../dashboard/RemovableDashboardCard";
import { GridLayout, GridItem } from "vue-grid-layout";
import GaugeWrapper from "../dashboard/GaugeWrapper";

import MapWrapper from "../dashboard/MapWrapper";

import GrafanaEmbeddedDashboard from "./GrafanaEmbeddedDashboard"; // "../components/grafana/GrafanaEmbeddedDashboard"
import BaseGrafanaDashboard from "./BaseGrafanaDashboard"; // Extend the Base Class 
import { Dashboards } from "../../data/appData";

const layoutKey = "Status-Draggable-Layouts";
let storedLayout = JSON.parse(localStorage[layoutKey] || null);

const userDashboards = Dashboards;

/** DEMO ONLY  */
const defaultDashboard = "https://demo1.aarcomm.io/api/grafana/d/7_3_iVTVz/test-clean-harbors-dashboard-v3?orgId=1";
const defaultDashboard2 = "https://demo1.aarcomm.io/api/grafana/d/Gta3wT-4k/test-clean-harbors-dashboard-v3-calgary?orgId=1";


export default {
    name: "GrafanaStatusDashboard",
    extends: BaseGrafanaDashboard, // Extend the base class, which comes with some utility methods built in 
    components: {
        GridLayout,
        GridItem,
        DashboardCard,
        RemovableDashboardCard,
        MapWrapper,
        GaugeWrapper,

        GrafanaEmbeddedDashboard
    },
    props: {
        selected: Object,
        from: {
            type: [String, Number],
            default: "now-8h",        
        },
        to:{
            type: [String, Number],
            default: "now",
        },
        url:{
            type: String, 
             
        }
    },

    data() {
        return {
            loaded: false,
            edit: false,
            //dashboardList: [], // TODO: Could this be a computed property? 
            key: 'realtime', // APP Specific Key
            //selected: null,
        };
    },
    computed: {
        dashboardList() {
            return this.mapData(this.$store.state.dataStore.dashboards[this.key]);
            //return this.$store.state.dataStore.dashboards;
        },

        urlPath () {
            
            if(this.url){
                return this.url; 
            }
            
            if (!this.selected)
                return defaultDashboard;

            /** Hack for demo */
            if(this.selected.branch_id==="1"){
                return defaultDashboard2; 
            }


            let temp = this.dashboardList.find(x => x.id == this.selected.device);
            console.log(temp);
            if (temp)
                return temp.value;
            else
                return defaultDashboard

            return temp;
        }
    },
    mounted() {
        // TODO:
        this.loaded = false;
        try {
            this.load();
        } catch (err) {
            console.log("Failed to load the users dashboards on Mount. " + err);
            // Use some defaults here
        }
    },
    activated() {

        this.load();
    },
    methods: {
        load() {
            console.log("Loading Status Dashboards");
            console.log(this.$store.state.dataStore.dashboards);
            //this.dashboardList = this.mapData(this.$store.state.dataStore.dashboards[this.key]);
        },
        onLoaded(e) {

            console.log("frame loaded. " + e);
            this.loaded = true;
        }



    }
};
</script>