var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "CRow",
    { staticClass: "m-0 p-0" },
    [
      _c(
        "CCol",
        { attrs: { lg: "12" } },
        [
          _c(
            "CCard",
            { attrs: { "accent-color": _vm.accentColor } },
            [
              _vm._t("header"),
              _c(
                "CCardBody",
                { staticClass: "m-0 p-0" },
                [_vm._t("default")],
                2
              ),
              _c(
                "CCardFooter",
                { staticClass: "m-0 p-0" },
                [_vm._t("footer")],
                2
              )
            ],
            2
          )
        ],
        1
      ),
      _c(
        "CElementCover",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.cover,
              expression: "cover"
            }
          ],
          attrs: {
            boundaries: [{ sides: ["top", "left"], query: "#media-body" }],
            opacity: 0.1
          }
        },
        [
          _vm._t("cover", [
            _c("h1", { staticClass: "d-inline" }, [
              _vm._v(_vm._s(_vm.coverMessage) + " ")
            ]),
            _c("CSpinner", { attrs: { size: "6xl", color: "info" } })
          ])
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }