
<template>
  <!-- Creates a Widget that we can re-use for admin pages -->
  <CRow class="py-0 my-0">
    <CCol col="12" class="py-0 my-0">
      <DashboardCard :color="theme" :accent="accent" :collapsible="false" :collapsed.sync="collapsed"
        class="dashboard-header-card">
        <template #header>
          <CIcon name="cil-info" /> Info
        </template>
        <template #body>
          <div >
          <div class="no-gutters my-0 py-0" >
            <CRow class="no-gutters my-0 py-0">
              <CCol col="3" class="pt-0">
                <TruckInfoCard
                :name="item.name"
                :device="item.device"
                icon=""
                ></TruckInfoCard>
                  
              </CCol>
              <CCol>
                <IoTMetadata ref="infocard" :info="info" />
              </CCol>
              <CCol class="">
                <CRow class="mt-4" v-if="!loading">
                  <CCol class="pr-0 pb-0 mb-0" col="12">
                    
                    <div class="message text-center p-0 m-0">
                      <CBadge class="message text-center p-0 m-0">
                        <span>{{ getMessage(item) }}</span>
                      </CBadge>
                    </div>
                  </CCol>
                  <CCol col="12" class="">

                    <span class="float-right mr-4" v-show="item.status">
                      <AlarmStatusBadge :item="item" :timestamp="item.timestamp">
                      </AlarmStatusBadge>
                    </span>
                  </CCol>
                </CRow>
              </CCol>
            </CRow>

          </div>

        </div>


        </template>
        

      </DashboardCard>
    </CCol>
  </CRow>
</template>

<style scoped>
.dashboard-header-card {
  max-height: 200px;
  margin-top: 0px;
  /*background-color: var(--theme-hightlight); */
}

.some-gutters {
  margin-right: 0;
  margin-left: 0;
  margin-bottom: -2px;
  padding-right: 0;
  padding-left: 1;
  padding-bottom: 0px;
}
</style>

<script>

import IoTMetadata from "../components/dashboard/IoTMetadata";
import DeviceActivity from "../components/timeline/DeviceActivity";
import DashboardCard from "../components/dashboard/DashboardCard";
import TruckStatusCard from "../views/app/TruckStatusCard.vue";
import AlarmStatusBadge from "@/components/badges/AlarmStatusBadge.vue";
import TruckInfoCard from "../views/app/TruckInfoCard.vue";
//import {deviceInfo,radioInfo} from "../../data/appData";

/** Some Default Data for Debugging Purposes. */

export default {
  name: "DashboardHeaderInfo",
  components: {
    IoTMetadata,
    DashboardCard,
    TruckStatusCard,
    TruckInfoCard,
    AlarmStatusBadge
  },
  props: {
    device: {
      type: Object,
    },
    info: {
      type: Object
      //default: defaultInfo,
    },
    theme: {
      type: String,
      default: "gradient-dark"
    },
    accent: {
      type: String,
      default: "danger",
    }, 
    loading:{
      type: Boolean, 
      default: false, 
    }
  },
  data() {
    return {
      collapsed: false,
    };
  },
  computed: {
    item() {
      return this.device;
    },
    lastConnected() {
      if (!this.$refs.infocard) {
        return "";
      }
      else {
        return `${this.$refs.infocard.showTime(this.$refs.infocard.lastConnected)}`;
      }
    }
  },
  methods: {
    getMessage(item) {
      return item.message ? item.message : "";
    },
  }
};
</script>
