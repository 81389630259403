var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "CRow",
        {
          staticClass: "justify-content-between pb-2 px-3",
          staticStyle: { "margin-top": "-10px" },
          attrs: { id: "media-body" }
        },
        [
          _c(
            "CCol",
            { attrs: { lg: "12", sm: "12", xs: "12" } },
            [
              _c("DeviceDropdownSelect", {
                attrs: {
                  options: _vm.deviceOptions,
                  value: _vm.selectedOption
                },
                on: { "update:value": _vm.onUpdate }
              }),
              _c(
                "CButton",
                {
                  staticClass: "ml-1 float-right",
                  attrs: { color: "info", variant: "outline" },
                  on: {
                    click: function($event) {
                      return _vm.refresh()
                    }
                  }
                },
                [_c("CIcon", { attrs: { name: "cil-sync" } })],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "CRow",
        { staticClass: "m-0 p-0" },
        [
          _c(
            "CCol",
            { staticClass: " m-0 p-0", attrs: { lg: "12", color: "warning" } },
            [
              _c("DefaultLayout", { staticClass: "p-0 m-0 header-container" }, [
                _c(
                  "div",
                  { staticClass: "header-container" },
                  [
                    _c("DashboardHeaderSimple", {
                      staticClass: "header-container",
                      attrs: {
                        info: _vm.deviceStatus.deviceInfo,
                        theme: _vm.$theme.backColor,
                        accent: _vm.$theme.accentColor,
                        device: _vm.selectedDeviceInfo,
                        alerts: _vm.alerts,
                        loading: _vm.isLoading
                      },
                      on: { "info-click": _vm.handleInfoClick }
                    }),
                    _c(
                      "CRow",
                      { staticClass: "pt-0 mt-0 " },
                      [
                        _c(
                          "CCol",
                          {
                            staticClass: "dashboard-container",
                            attrs: { lg: "12" }
                          },
                          [
                            _c("CRelativeTimePicker", {
                              attrs: {
                                options: _vm.timePicker.relativeTimes,
                                value: _vm.relativeTimeSelected
                              },
                              on: {
                                "update:value": function($event) {
                                  _vm.relativeTimeSelected = $event
                                }
                              }
                            }),
                            _c("GrafanaStatusDashboard", {
                              key: _vm.updated,
                              staticClass: "p-0 m-0",
                              attrs: {
                                selected: _vm.selected,
                                url: _vm.url,
                                from: _vm.from,
                                to: _vm.to
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ])
            ],
            1
          )
        ],
        1
      ),
      _c(
        "CElementCover",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.isLoading,
              expression: "isLoading"
            }
          ],
          attrs: {
            boundaries: [{ sides: ["top", "left"], query: "#media-body" }],
            opacity: 0.1
          }
        },
        [
          _c("h1", { staticClass: "d-inline" }, [_vm._v("Loading...")]),
          true
            ? _c("CKebabSpinner")
            : _c("CSpinner", { attrs: { size: "6xl", color: "info" } })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }