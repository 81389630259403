var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "CRow",
    { staticClass: "py-0 my-0" },
    [
      _c(
        "CCol",
        { staticClass: "py-0 my-0", attrs: { col: "12" } },
        [
          _c("DashboardCard", {
            staticClass: "dashboard-header-card",
            attrs: {
              color: _vm.theme,
              accent: _vm.accent,
              collapsible: false,
              collapsed: _vm.collapsed
            },
            on: {
              "update:collapsed": function($event) {
                _vm.collapsed = $event
              }
            },
            scopedSlots: _vm._u([
              {
                key: "header",
                fn: function() {
                  return [
                    _c("CIcon", { attrs: { name: "cil-info" } }),
                    _vm._v(" Info ")
                  ]
                },
                proxy: true
              },
              {
                key: "body",
                fn: function() {
                  return [
                    _c("div", [
                      _c(
                        "div",
                        { staticClass: "no-gutters my-0 py-0" },
                        [
                          _c(
                            "CRow",
                            { staticClass: "no-gutters my-0 py-0" },
                            [
                              _c(
                                "CCol",
                                { staticClass: "pt-0", attrs: { col: "3" } },
                                [
                                  _c("TruckInfoCard", {
                                    attrs: {
                                      name: _vm.item.name,
                                      device: _vm.item.device,
                                      icon: ""
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "CCol",
                                [
                                  _c("IoTMetadata", {
                                    ref: "infocard",
                                    attrs: { info: _vm.info }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "CCol",
                                {},
                                [
                                  !_vm.loading
                                    ? _c(
                                        "CRow",
                                        { staticClass: "mt-4" },
                                        [
                                          _c(
                                            "CCol",
                                            {
                                              staticClass: "pr-0 pb-0 mb-0",
                                              attrs: { col: "12" }
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "message text-center p-0 m-0"
                                                },
                                                [
                                                  _c(
                                                    "CBadge",
                                                    {
                                                      staticClass:
                                                        "message text-center p-0 m-0"
                                                    },
                                                    [
                                                      _c("span", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.getMessage(
                                                              _vm.item
                                                            )
                                                          )
                                                        )
                                                      ])
                                                    ]
                                                  )
                                                ],
                                                1
                                              )
                                            ]
                                          ),
                                          _c("CCol", { attrs: { col: "12" } }, [
                                            _c(
                                              "span",
                                              {
                                                directives: [
                                                  {
                                                    name: "show",
                                                    rawName: "v-show",
                                                    value: _vm.item.status,
                                                    expression: "item.status"
                                                  }
                                                ],
                                                staticClass: "float-right mr-4"
                                              },
                                              [
                                                _c("AlarmStatusBadge", {
                                                  attrs: {
                                                    item: _vm.item,
                                                    timestamp:
                                                      _vm.item.timestamp
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          ])
                                        ],
                                        1
                                      )
                                    : _vm._e()
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ])
                  ]
                },
                proxy: true
              }
            ])
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }