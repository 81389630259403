
<template>
    <div class="">
        <CSelect id="selector" class="pb-0 mb-0 rounded float-left button border border-info" 
          :options="deviceOptions" :value="selectedOption" @update:value="onUpdate">
          <template #prepend-content>
            <slot name="prepend-content"></slot>
            <CIcon style="color: var(--info)" name="cil-truck" />
          </template>
        </CSelect>
    </div>
</template>
<style scoped>
#selector{
   min-width: 250px; 
   width:100%;
}
</style>


<script>
/** 
 * Defines a Custom component for Selecting Devices from Drop down List 
 * Displayes the Name as the Label in the Select 
 */
import CSingleSelect from '../base/CSingleSelect.vue';

 export default {
    name: "DeviceDropdownSelect", 
    props:{
        options:{
        type: Array,
        }, 
        value:{
            type: [String, Object], 

        }
        
    }, 
    data(){
        return{
            selected: undefined, 
        }
    },
    computed:{
        deviceOptions(){
            return this.options; 
        }, 
        selectedOption:{
            get(){
                return this.value
            }, 
            set(val){
                // TODO: 
            }
        }
    } , 
    methods:{
        onUpdate(e){
            // This Emit Change 
            this.$emit("update:value", e);
            
        }
    }, 
    mounted:{

    }
    
 }

</script>