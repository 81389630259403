var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "CRow",
        { staticClass: "p-0" },
        [
          _c(
            "CCol",
            [
              _vm._t("label", [
                _c("CFormLabel", {
                  attrs: {
                    required: _vm.required,
                    "required-message": _vm.requiredMessage,
                    label: _vm.label,
                    tooltip: _vm.tooltip
                  }
                })
              ])
            ],
            2
          )
        ],
        1
      ),
      _c(
        "CRow",
        [
          _c(
            "CCol",
            { attrs: { col: _vm.col } },
            [
              _c("multiselect", {
                class: { invalid: _vm.isInvalid, rounded: true },
                attrs: {
                  disabled: _vm.disabled,
                  multiple: false,
                  search: true,
                  active: true,
                  "max-height": 200,
                  "option-height": 40,
                  options: _vm.displayedOptions,
                  "hide-selected": false,
                  optionsEmptyPlaceholder: "No Items To Show",
                  label: "label",
                  "track-by": "name",
                  "show-labels": true,
                  open: true,
                  "close-on-select": true,
                  "group-select": false,
                  taggable: _vm.taggable,
                  "allow-empty": false,
                  "deselect-label": "",
                  placeholder: _vm.placeholder,
                  "select-label": _vm.selectLabel
                },
                on: {
                  select: _vm.onSelect,
                  input: _vm.onInput,
                  tag: _vm.onTag,
                  close: _vm.onTouched
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "singleLabel",
                      fn: function(props) {
                        return [_vm._t("default", null, null, props)]
                      }
                    },
                    {
                      key: "option",
                      fn: function(props) {
                        return [_vm._t("option", null, null, props)]
                      }
                    }
                  ],
                  null,
                  true
                ),
                model: {
                  value: _vm.selectedOptions,
                  callback: function($$v) {
                    _vm.selectedOptions = $$v
                  },
                  expression: "selectedOptions"
                }
              })
            ],
            1
          ),
          _vm.showEdit
            ? _c(
                "CCol",
                { staticClass: "m-0 p-0", attrs: { col: "1" } },
                [
                  _c(
                    "CButton",
                    {
                      attrs: { color: "info", variant: "ghost" },
                      on: { click: _vm.handleEditClick }
                    },
                    [_c("CIcon", { attrs: { name: "cil-pencil" } })],
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }