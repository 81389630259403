<template>
  <div>
    <CRow id="media-body" class="justify-content-between pb-2 px-3" style="margin-top: -10px">
      <CCol lg="12" sm="12" xs="12">
        <DeviceDropdownSelect 
        :options="deviceOptions"
        :value="selectedOption"
        @update:value="onUpdate"
        >

        </DeviceDropdownSelect>


        <CButton class="ml-1 float-right" color="info" variant="outline" @click="refresh()">
          <CIcon name="cil-sync" />
        </CButton>
      </CCol>
    </CRow>
    <CRow class="m-0 p-0">
      <CCol lg="12" color="warning" class=" m-0 p-0">
        <DefaultLayout class="p-0 m-0 header-container">
          <div class="header-container">
            <DashboardHeaderSimple class="header-container" :info="deviceStatus.deviceInfo" :theme="$theme.backColor"
              :accent="$theme.accentColor" :device="selectedDeviceInfo" :alerts="alerts"  :loading="isLoading"
              @info-click="handleInfoClick"
              >
            </DashboardHeaderSimple>
            
            <CRow class="pt-0 mt-0 " >
              <CCol lg="12" class="dashboard-container">

                <CRelativeTimePicker
                    :options="timePicker.relativeTimes" :value.sync="relativeTimeSelected"
                      >
                    </CRelativeTimePicker>

                <GrafanaStatusDashboard class="p-0 m-0" :selected="selected" :url="url" :from="from"
                :to="to" :key="updated" />

              </CCol>
            </CRow>
          </div>


        </DefaultLayout>

      </CCol>
    </CRow>

    <CElementCover v-show="isLoading" :boundaries="[{ sides: ['top', 'left'], query: '#media-body' }]" :opacity="0.1">
      <h1 class="d-inline">Loading...</h1>
      <CKebabSpinner v-if="true"></CKebabSpinner>
      <CSpinner v-else size="6xl" color="info" />
    </CElementCover>
  </div>
</template>

<script>



import GrafanaStatusDashboard from "@/components/grafana/GrafanaStatusDashboard";
import DashboardStatusHeader from "@/widgets/DashboardStatusHeader";
import DashboardHeaderSimple from "@/widgets/DashboardHeaderSimple"
import statusHelper from "@/lib/hydrovac_lib/statusHelper";
import CKebabSpinner from "../components/base/CKebabSpinner.vue";
import CardLayout from '../layouts/CardLayout.vue';
import DefaultLayout from "../layouts/DefaultLayout.vue";
import CRelativeTimePicker from "@/components/time/CRelativeTimePicker.vue";
const { HydrovacStatus } = statusHelper;

import {MS_PER_MINUTE, MS_PER_DAY, MS_PER_HOUR} from "@/components/time/constants"; 
import DeviceDropdownSelect from '@/components/input/DeviceDropdownSelect.vue';

import {defaultTimes, defaultDashboard, 
  defaultDashboard2, 
} from "@/data/appData.js"; 

const CALGARY_ID = "1";
const rangeMS = 1 * MS_PER_HOUR;

export default {
  name: "DashboardSimple",
  components: {
    CKebabSpinner,
    DashboardStatusHeader,
    DashboardHeaderSimple,
    GrafanaStatusDashboard,
    CardLayout,
    DefaultLayout,
    CRelativeTimePicker,
    DeviceDropdownSelect
},
  data() {

    return {
      selected: null,// Object // TODO: this should be a Calculated value as well with a getter and setter
      gridKey: 0,
      updated: 0,
      isLoading: false,
      //deviceOptions: [],
      activeIndex: 0,
      //devices: [], // deviceList,
      deviceStatus: {
        radioInfo: {},
        recentActivity: [],
        deviceInfo: {},
        statusInfo: {},
        alerts: [], // Collection 
      },
      show: false,
      selectedOption: null,
      rangeMS: rangeMS, // Default is last hour minutes
      timePicker : {
        slectedStart: "now-8h",
        selectedEnd: "now", // Always now 
        selectedRangeID: "8h",
        selectedRange: undefined, 
        relativeTimes: defaultTimes,
      }
      
    };
  },
  computed: {

    from() {
      return this.timePicker.slectedStart;
    },
    to() {
      return this.timePicker.selectedEnd;
    },
    relativeTimeSelected: {
      get() {
        let temp = this.timePicker.selectedRangeID;
        if (temp)
          return temp;
        return this.timePicker.relativeTimes[0].value;  

      },
      set(value) {
        this.timePicker.selectedRangeID = value;
        let temp = this.timePicker.relativeTimes.find(x => x.id === this.selectedRangeID);
        this.timePicker.selectedRange = temp; 
        if(temp)
          this.rangeMS = temp.range;

          this.timePicker.slectedStart = "now-" + value;
      }


    },
    /** Gets/ Sets the Selected Item from the drop dowm list
     * Setting the value will clear controls and fetch new info from the server
     */
    selectedItemName() {
      let togglerText = "";
      try {
        if (this.selected) {
          // If We've Selected A Deivce
          togglerText = this.selected.name.toString();
        } else {
          togglerText = "No Device Selected";
        }
      } catch (err) {
        console.log(err);
      }
      return togglerText;
    },
    isShown() {
      let deviceId = this.$route.query["id"];
      if (this.selected && this.selected.device === deviceId) {
        return true;
      }
      return false;
    },
    selectedDeviceInfo() {
      return this.deviceStatus.statusInfo;
    },
    url(){
      if(!this.selected){
        return ""; 
      }
      if(this.selected.branch_id===CALGARY_ID){
        return defaultDashboard2;
      }
      else{
        return defaultDashboard; 
      }
    }, 
    alerts(){
      return this.deviceStatus.alerts; 
    }, 
    devices() {
      return this.mapDevices(this.inventory);
    },
    deviceOptions() {
      let temp =
        this.inventory && this.inventory.length > 0
          ? this.inventory.map((element, index) => {
            return {
              id: index,
              device: element.device,
              value: element.device,
              tag: element,
              label: element.name,
            };
          })
          : [];
      console.log(temp);
      return temp;
    },
    inventory() {
      let branch_id = this.$store.getters.selectedBranch
        ? this.$store.getters.selectedBranch.branch_id
        : "1";
      return this.$store.getters.inventory
        ? this.$store.getters.inventory.filter(
          (item) => item.status != "ready" && item.branch_id === branch_id
        )
        : [];
    },
  },
  created() {

  },

  async activated() {

    this.relativeTimeSelected = this.timePicker.selectedRangeID; 

    if (!this.isShown) {
      this.load();
    }
    this.forceUpdateIFrame();
    //await this.load();
    this.timerHandle = setInterval(() => {
      this.update();
    }, 10000);

    if (!this.devices) {
      this.devices = this.inventory.map((item, index) => {
        return item;
      });
    }
    this.show = true;
  },
  async deactivated() {
    //this.load();
    this.show = false;
    if (this.timerHandle) {
      clearInterval(this.timerHandle);
    }
  },
  async mounted() {
    // TODO: Persist the Data in Local Storage to avoid this query again!
    this.relativeTimeSelected = this.timePicker.selectedRangeID; 
    
    await this.load();
  },
  methods: {

    handleInfoClick(e){
      let deviceId = this.selected.device; 
      const path = `/demo/devices/${deviceId}/manage`; 
      let params = {
        device: deviceId,
      }
      this.$router.push(path,params); 
    }, 
    onTabUpdate(e) {
      this.forceUpdateIFrame();
      this.refresh(); // TODO: Do we need to refresh if we navigate away???!
    },
    forceUpdateIFrame() {
      // We just need to update the key, which will force an update
      this.updated += 1;
    },
    getClass(index) {
      if (this.activeIndex != index) return "tab-top";
      else {
        return "tab-top bg-transparent   active ";
      }
    },
    async update() {
      try {
        if (!this.selected) {
          return;
        }
        let item = this.selected;
        let param = {
          id: item.device,
          //name: item.name,
          device: item.device,
          select: "last",
          //truck: item.name // TODO:Should Not Be Named truck
        };
        console.log("Updating");
        let temp = await this.$api.getDeviceInfoByID(item.device, param);
        if (temp.status === 200)
          this.deviceStatus.deviceInfo = Object.assign({}, temp.data);
        temp = await this.$api.getSensorByID(item.device, param);
        if (temp.status === 200) {
          //this.deviceStatus.
        }
        temp = await this.$api.getDeviceActivityByID(item.device, param);
        if (temp.status === 200) {
          this.deviceStatus.recentActivity = temp.data;
        }

        await this.updateStatus();
        param = {
          //name: item.name,
          //truck: item.name,
          device: item.device,
          select: "last",
        };
        /*
        temp = await this.$api.getRadioByID(item.name, param);

        if (temp.status === 200 && temp.data.length > 0) {
          if (!Array.isArray(temp.data[0])) {
            this.deviceStatus.radioInfo = temp.data[0];
          }
        }
        */
      } catch (err) {
        console.log(err);
      }
    },
    onUpdate(e) {
      console.log(e);
      let temp = this.devices.find((item) => item.device === e);
      this.onSelect(temp);
    },
    async load() {
      let deviceIndex = 0;
      let deviceId = this.$route.query["id"];
      try {
        if (!this.devices || this.devices.length == 0) {
          this.isLoading = true;
          try {
            let data = await this.loadInventory();
            console.log(data);
            if (data) {
              this.mapDevices(data);
              let element = this.devices.find((item) => item.device === deviceId);

              if (element && deviceId === element.device) {
              } else {
                deviceIndex = 0;
                element = this.devices[deviceIndex];
              }
              // Notify Complete

              this.onSelect(element);
            }
          } catch (err) {
            console.warn(err);
          }
          //this.$store.commit("updateData", ["inventory", this.devices]);
          this.isLoading = false;
        } else {
          let temp = this.devices.find((device) => device.device === deviceId);
          if (!temp) {
            // Select the First Item.
            temp = this.devices[0];
          }
          this.onSelect(temp);
        }
      } catch (err) {
        console.log(err);
      }
    },
    mapDevices(data) {
      let devices = []; //
      let deviceOptions = [];
      for (var index = 0; index < data.length; index++) {
        let element = data[index];

        devices.push(element);
        /*
              deviceOptions.push({
                id: element.device,
                device: element.device,
                value: element.device,
                tag: element,
                label: element.name,
              });
              */
      }
      return devices;
    },
    async loadDashboards() {
      console.log("Retrieveing Dashboards");

    },
    /** Deep Copy  */
    copyReactiveObject(source, dest) {
      let keys = Object.keys(source);
      for (let i = 0; i < keys.length; i++) {
        let key = keys[i];
        console.log(key);
        if (dest[key]) {
          dest[key] = source[key];
        }
      }
    },
    async loadInventory() {
      try {
        //if(!this.inventory){
        console.log("Loading Inventory");
        let branch_id = this.$app.myBranchId;
        let params = { branch_id };
        await this.$app.loadInventory(params);


      } catch (err) {
        console.log(err);
      }
      return this.inventory;
    },
    async refresh() {
      if (!this.selected) {
        return;
      }
      try {
        this.isLoading = true;

        let item = this.selected;
        let param = {
          id: item.device,
          //name: item.name,
          device: item.device,
          select: "last",
          //truck: item.name // TODO:Should Not Be Named truck
        };
        // Show Loading Here
        console.log("Refeshing");

        let temp = await this.$api.getDeviceInfoByID(item.device, param);
        if (temp.status === 200)
          this.deviceStatus.deviceInfo = Object.assign({}, temp.data);
        temp = await this.$api.getSensorByID(item.device, param);
        if (temp.status === 200) {
          //this.deviceStatus.
        }
        temp = await this.$api.getDeviceActivityByID(item.device, param);
        if (temp.status === 200) {
          this.deviceStatus.recentActivity = temp.data;
        }
        param = {
          //name: item.name,
          //truck: item.name,
          device: item.device,
          select: "last",
        };
        temp = await this.$api.getRadioByID(item.name, param);

        if (temp.status === 200 && temp.data.length > 0) {
          if (!Array.isArray(temp.data[0])) {
            this.deviceStatus.radioInfo = temp.data[0];
          }
        }
        this.updateStatus();

      } catch (err) {
        console.warn(err);
      }

      //this.isLoading = false;
      setTimeout(() => (this.isLoading = false), 500);

    },



    getColor(item) {
      let color = "primary";
      switch (item.status) {
        case "alarming":
          color = "danger";
          break;
        case "warning":
          color = "warning";
          break;
        case "idle":
          color = "info";
          break;
        case "moving":
          color = "success";
          break;
        case "stopped":
          color = "secondary";
          break;
      }
      return color;
    },
    loadEquation(item) {
      let pressureSensor = item.sensors[0];
      let equation = this.$equationMGR.getEquation(pressureSensor.equation);

      return equation;
    },
    encodeTimestamp(time, unit = "ms") {
      if (typeof time === "string") {
        return time;
      } else if (time instanceof Date) {
        return time.getTime().toString() + unit;
      } else {
        return time.toString() + unit;
      }
    },
    async getAlarmStatus(item) {
      try {
        let res = await this.$alerting.getAlarmStatus(item);
        return res;
      } catch (err) {
        console.log(err);
      }
    },
    async loadStatus(item) {
      try {
        let device = item.device;
        let resp = await this.$api.getDeviceInfoByID(device);
        if (resp && resp.status == 200) {
          item.deviceInfo = resp.data;
        }
      } catch (e) { 
        console.log(e); 
      }
    },


    async loadAlerts(){
      try{
          let end = Date.now(); 
          let start = end - MS_PER_DAY*2; 

          let results = await this.$alerting.getAlarms(this.selected, start, end); 

          console.log(results); 
          // TODO: Order by Time and return the most recent
          results.sort((a,b)=>{
            return (a.timestamp-b.timestamp); 
          })

          return results; 

  

      }
      catch(err){
        console.log(err); 
      }
    }, 

    getCachcedStatus(){
              // Get the Cached Status here. 

        let temp = this.$alerting.alertStatus.filter(item=>item.device===this.selected); 

        if(temp){
          this.deviceStatus.alerts = temp; 
        }
    }, 
    async updateStatus() {
      try {
        let branch_id = this.$app.myBranchId;

       


        // Load the alerts for the the device 
        let myStatus = await this.$alerting.getDeviceStatus(this.selected);
        console.log(myStatus); 
        if(this.deviceStatus)
          this.deviceStatus.statusInfo = myStatus;

        
        // Fetch Old Alerts 
        let alerts = await this.loadAlerts();
        if(!alerts || alerts.length){
           // TODO: Display Last known alert: ie: Last Alert was moment ago. We will display this in Status Widget 
           this.deviceStatus.alerts = alerts; 
        }

        // TODO: Fetch the Fleet Status 

        //await this.loadStatusInfo(this.selected);
        /*
        if (this.deviceStatus.statusInfo) {
          
          if (this.deviceStatus.statusInfo.device !== device) {
            this.deviceStatus.statusInfo = this.getStatus(this.selected);
          }
        } else {
          await this.loadStatusInfo(device); 
        }
        */
      } catch (err) {
        console.log(err);
      }

      this.statusUpdate += 1;
    },


    async onSelect(item) {
      // TODO: Is this device in List of Devices?
      console.log("Selecting", item);
      if (item) {
        this.selected = item;
        let option = this.deviceOptions.find((x) => x.value === item.device);
        console.log(option);
        this.selectedOption = option.value; // Must be String

        this.getCachcedStatus(); 
        await this.refresh();
      }

      // TODO: Handle Selected Item. Request Data from Server ( or Load Cached Data ) and update the Display
      //alert("You Selected Item: " + item.name);
      /** Use Axios to Get Latest Activity from Server as well as Radio Info and IoT Device Info  */

      /** TODO: Load the Dashboard Configuration for the Specific Device ID */
    },
  },
};
</script>

<style scoped>
.dashboard-container {
  min-height: 500px;
  overflow: hidden;
  min-height: 500px;
  height: 100%;
  overflow: hidden;
  margin-top: -20px;
  margin-left: -2px;
  margin-right: -2px;
  display: block;
  background-color: rgba(0, 0, 0, 0);
  z-index: 1;
}

.historical-dashboard-container {
  min-height: 500px;
  overflow: hidden;
  min-height: 500px;
  height: 100%;
  overflow: hidden;
  margin-top: 0px;
  display: block;
  background-color: rgba(0, 0, 0, 0);
}

.header-container {
  overflow: hidden;
  min-height: 100px;
  height: 100%;

  display: block;
  background-color: rgba(0, 0, 0, 0);
  z-index: 9999;
}

.button {
  /*background-color: var(--theme-gradient);*/
  /*background-image: var(--theme-gradient);*/
  border-width: 0px;
  border-style: solid;
  /*border-image: var(--theme-gradient);*/
}

.button-color {
  background-color: var(--info);
}

.vue-grid-item.vue-grid-placeholder {
  background: gray !important;
  border: thin dashed !important;
}
</style>
