var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "CRow",
    { staticClass: "py-0 my-0" },
    [
      _c(
        "CCol",
        { staticClass: "py-0 my-0", attrs: { col: "12" } },
        [
          _c("DashboardCard", {
            staticClass: "dashboard-header-card",
            attrs: {
              color: _vm.theme,
              accent: _vm.accent,
              collapsible: false,
              collapsed: _vm.collapsed
            },
            on: {
              "update:collapsed": function($event) {
                _vm.collapsed = $event
              }
            },
            scopedSlots: _vm._u([
              {
                key: "header",
                fn: function() {
                  return [
                    _c(
                      "span",
                      [
                        _c(
                          "CButton",
                          {
                            attrs: {
                              color: "light",
                              size: "sm",
                              variant: "ghost"
                            },
                            on: { click: _vm.handleInfoClick }
                          },
                          [
                            _c("CIcon", {
                              attrs: { name: "cil-info", href: "" }
                            })
                          ],
                          1
                        ),
                        _vm._v(" Truck: " + _vm._s(_vm.truck) + " ")
                      ],
                      1
                    ),
                    _c(
                      "span",
                      { staticClass: "table-overflow-elipses ml-1 mt-0 " },
                      [
                        _c(
                          "small",
                          [
                            _c("ConnectionStatusBadge", {
                              attrs: { value: _vm.getOnlineState() }
                            })
                          ],
                          1
                        )
                      ]
                    )
                  ]
                },
                proxy: true
              },
              {
                key: "body",
                fn: function() {
                  return [
                    _c("div", [
                      _c(
                        "div",
                        { staticClass: "no-gutters my-0 py-0 py-0" },
                        [
                          _c(
                            "CRow",
                            { staticClass: "no-gutters my-0 py-0" },
                            [
                              _c(
                                "CCol",
                                {
                                  staticClass: "pt-0 p-0 m-0",
                                  attrs: { col: "3" }
                                },
                                [
                                  _c(
                                    "CRow",
                                    [
                                      _c("CCol", [
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "cards-label card-text-light"
                                          },
                                          [_vm._v("Last Connected")]
                                        ),
                                        _c(
                                          "span",
                                          {
                                            staticClass: "cards-text",
                                            attrs: { id: "last-connected" }
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.showTime(_vm.lastConnected)
                                              )
                                            )
                                          ]
                                        )
                                      ])
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "CCol",
                                [
                                  false
                                    ? _c(
                                        "CRow",
                                        [
                                          _c("CCol", [
                                            _vm.info.radio
                                              ? _c("div", [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "cards-label card-text-light"
                                                    },
                                                    [_vm._v("Receiver Type")]
                                                  ),
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass: "cards-text"
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(_vm.info.radio)
                                                      )
                                                    ]
                                                  )
                                                ])
                                              : _vm._e()
                                          ])
                                        ],
                                        1
                                      )
                                    : _vm._e()
                                ],
                                1
                              ),
                              _c(
                                "CCol",
                                { staticClass: "alarm-container" },
                                [
                                  !_vm.loading
                                    ? _c(
                                        "CRow",
                                        { staticClass: "mt-0 float-right" },
                                        [
                                          _c(
                                            "CCol",
                                            [
                                              _c(
                                                "CRow",
                                                {
                                                  staticClass: "m-0 p-0",
                                                  attrs: { col: "12" }
                                                },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      directives: [
                                                        {
                                                          name: "show",
                                                          rawName: "v-show",
                                                          value:
                                                            _vm.item.status,
                                                          expression:
                                                            "item.status"
                                                        }
                                                      ],
                                                      staticClass:
                                                        "m-0 p-0 my-0"
                                                    },
                                                    [
                                                      _c("AlarmStatusBadge", {
                                                        attrs: {
                                                          item: _vm.item,
                                                          timestamp:
                                                            _vm.item.timestamp,
                                                          humanize: false
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  )
                                                ]
                                              ),
                                              _vm.item.status != "stopped" &&
                                              _vm.getMessage(_vm.item)
                                                ? _c(
                                                    "CRow",
                                                    {
                                                      staticClass: "p-0 m-0",
                                                      attrs: { col: "12" }
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "message text-center p-0 m-0"
                                                        },
                                                        [
                                                          _c(
                                                            "CBadge",
                                                            {
                                                              staticClass:
                                                                "message text-center p-0 m-0"
                                                            },
                                                            [
                                                              _c("span", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.getMessage(
                                                                      _vm.item
                                                                    )
                                                                  )
                                                                )
                                                              ])
                                                            ]
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ]
                                                  )
                                                : _c(
                                                    "CRow",
                                                    {
                                                      staticClass:
                                                        "message text-center p-0",
                                                      attrs: { col: "12" }
                                                    },
                                                    [
                                                      _c("span", [
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "card-text-light"
                                                          },
                                                          [
                                                            _vm._v(
                                                              "Last Alert: "
                                                            ),
                                                            _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "text-center"
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.getLastAlertTime()
                                                                  )
                                                                )
                                                              ]
                                                            )
                                                          ]
                                                        )
                                                      ])
                                                    ]
                                                  )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    : _c(
                                        "CRow",
                                        [
                                          _vm.variant === "kebab"
                                            ? _c("CKebabSpinner")
                                            : _c("CSpinner", {
                                                attrs: {
                                                  size: "6xl",
                                                  color: "info"
                                                }
                                              })
                                        ],
                                        1
                                      )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ])
                  ]
                },
                proxy: true
              }
            ])
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }