<template>
  <div >
    <CRow class="p-0">
      <CCol>
        <slot name="label"> 
         <CFormLabel 
          :required="required"
          :required-message="requiredMessage"
          :label="label"
          :tooltip="tooltip"
         />
         </slot>
        
      </CCol>
    </CRow>
    <CRow>
      <CCol :col="col"  >
        <multiselect
          :class="{invalid:isInvalid, rounded:true}"
          :disabled="disabled"
          :multiple="false"
          :search="true"
          :active="true"
          :max-height="200"
          :option-height="40"
          :options="displayedOptions"
          v-model="selectedOptions"
          :hide-selected="false"
          optionsEmptyPlaceholder="No Items To Show"
          label="label"
          track-by="name"
          :show-labels="true"
          :open="true"
          :close-on-select="true"
          :group-select="false"
          :taggable="taggable"
          :allow-empty="false"
          deselect-label=""
          :placeholder="placeholder"
          :select-label="selectLabel"
          @select="onSelect"
          @input="onInput"
          @tag="onTag"
          @close="onTouched"
        >
          <template slot="singleLabel" slot-scope="props">
            <slot name="default" v-bind="props"> </slot>
          </template>
          <template slot="option" slot-scope="props">
            <slot name="option" v-bind="props"></slot>
          </template>
        </multiselect>
      </CCol>
      <CCol v-if="showEdit" col="1" class="m-0 p-0">
        <CButton color="info" variant="ghost" @click="handleEditClick">
          <CIcon name="cil-pencil" />
        </CButton>
      </CCol>
    </CRow>
  </div>
</template>


<script>
/** Wrapper around Vue-MultiSelect to perform simple Drop down Selections with added Validation
 *
 */

import Multiselect from "vue-multiselect";
import "vue-select/dist/vue-select.css";
import "vue-multiselect/dist/vue-multiselect.min.css";

import CFormLabel from "./CFormLabel"; 
import CFormInputBase from "./CFormInputBase";  

export default {
  name: "CSingleSelect",
  extends:CFormInputBase,
  components: {
    Multiselect,
    CFormLabel
  },
  props: {
    label: String,
    taggable: {
      type: Boolean,
      default: true,
    },
    col: {
      type: String,
      default: ""
    },
    enabled: {
      type: Boolean,
      default: true,
    },
    showEdit: {
      type: Boolean,
      default: false,
    },

    placeholder: { type: String, default: () => "Choose an Item" },
    selectLabel: { type: String, default: () => "Click to Select" },
    options: {
      type: Array,
    },
    value: {
      type: Object | Array | String,
    },
  },
  data() {
    return {
      selected: [],
      isTouched: false,
      displayed: [],
    };
  },
  computed: {
    isInvalid () {
      if(this.isValid===undefined){
        return false; 
      }
      return !this.isValid;
    },
    displayedOptions: {
      get() {
        return this.displayed;
        let ret = this.options.map((item, index) => {
          if (item) {
            return {
              id: index,
              item: item,
              name: item ? item.toString() : "",
              label: item ? item.toString() : "",
            };
          }
        });

        return ret.filter((item) => !(item == null || item == undefined));
      },
    },
    selectedOptions: {
      get() {
        return this.selected;
      },
      set(val) {
        console.log(val);
        // Update Value and Emit Event.
        if (val) {
          this.selected = [val];
          this.$emit("update:value", val.item);
        } else {
          this.selected = [];
          this.$emit("update:value", null);
        }
      },
    },
    disabled: {
      get() {
        return !this.enabled;
      },
      set(val) {
        console.log(!val);

        this.$emit("update:enabled", !val);
        this.$emit("update", val);
      },
    },
  },
  watch: {
    options:{
      immediate: true,
      deep: true,
      handler(newVal,oldVal){
        this.updateDisplayed(newVal,oldVal);
      }
    },
    value: {
      immediate: true,
      deep: true,
      handler(newVal, oldVal) {
        // find value in options and return that
        console.log(newVal, oldVal);
        if (!newVal) {
         this.selected = [];
         return; 
        }
        this.selectItem(newVal);
        
      },
    },
  },
  methods: {
    selectItem(e,index){
      let temp = this.displayedOptions.find((item) => item.item === e);
        console.log(temp);
        if (temp) {
          this.selected = [temp];
        } else {
          this.selected = [];
        }
    },
    updateDisplayed(newVal,oldVal){
      let ret = newVal.map((item, index) => {
          if (item) {
            return {
              id: index,
              item: item,
              name: item ? item.toString() : "",
              label: item ? item.toString() : "",
            };
          }
        });

        this.displayed =  ret.filter((item) => !(item == null || item == undefined));

    },
    handleEditClick(e, sender) {
      this.disabled = !this.disabled;
      if (sender) {
        sender.focus();
      }
    },
    onSelect(e, id) {
      //this.$emit("update:value", e.item);
      console.log(e,id);
    },
    onInput(e,id) {
      //this.$emit("update:value", e.item);
      console.log(e,id);
    },
    onTag(e,id){
      
      let temp = this.options.find(item=>item===e);
      if(!temp){
        this.options.push(e);
        this.updateDisplayed(this.options);
      }
      
      this.selectItem(e);
      this.$emit("update:value", e);
      //console.log(e,id);
      //console.log(this.selected);
      //console.log(this.options);
      
     
      
      
    },
    onTouched (e) {
      this.isTouched = true
    }
  },
};
</script>

