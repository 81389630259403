var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {},
    [
      _c("CSelect", {
        staticClass: "pb-0 mb-0 rounded float-left button border border-info",
        attrs: {
          id: "selector",
          options: _vm.deviceOptions,
          value: _vm.selectedOption
        },
        on: { "update:value": _vm.onUpdate },
        scopedSlots: _vm._u(
          [
            {
              key: "prepend-content",
              fn: function() {
                return [
                  _vm._t("prepend-content"),
                  _c("CIcon", {
                    staticStyle: { color: "var(--info)" },
                    attrs: { name: "cil-truck" }
                  })
                ]
              },
              proxy: true
            }
          ],
          null,
          true
        )
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }