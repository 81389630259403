var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.info
        ? _c(
            "CRow",
            [
              _c("CCol", [
                _c("span", { staticClass: "cards-label card-text-light" }, [
                  _vm._v("IMEI")
                ]),
                _c("span", { staticClass: "cards-text" }, [
                  _vm._v(_vm._s(_vm.info.imei))
                ]),
                _vm.info.radio
                  ? _c("div", [
                      _c(
                        "span",
                        { staticClass: "cards-label card-text-light" },
                        [_vm._v("Receiver Type")]
                      ),
                      _c("span", { staticClass: "cards-text" }, [
                        _vm._v(_vm._s(_vm.info.radio))
                      ])
                    ])
                  : _vm._e(),
                _c("span", { staticClass: "cards-label card-text-light" }, [
                  _vm._v("Last Connected")
                ]),
                _c(
                  "span",
                  {
                    staticClass: "cards-text",
                    attrs: { id: "last-connected" }
                  },
                  [_vm._v(_vm._s(_vm.showTime(_vm.lastConnected)))]
                )
              ])
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }