
<template>
  <div class="media text-muted pt-3 message">
    <div class= "py-0 pb-0 mr-3 float-left rounded">
    <div class="c-avatar">
    <svg
      class="bd-placeholder-img mr-2 rounded img-svg"
      width="32"
      height="32"
      xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio="xMidYMid slice"
      focusable="false"
      role="'/' +img"
      aria-label="Placeholder: 32x32"
    >
      <title>{{ id }}</title>
      <rect :fill="color"  
        :stroke="defaultStroke"
        rx="5" 
        ry="5"
        style="stroke-width:1;"
        class="rouded"

      width="100%" height="100%"></rect>
      <image
        :href="myImg"
        :alt="defaultImg"
        class="rounded bd-placeholder-img mr-2"
        width="100%"
        height="100%"
        
      />
    </svg>
    </div>
    </div>
    <div class="pb-3 mb-0 lh-125 border-bottom border-gray">
      <div class="d-block text-gray-dark font-weight-bold title-text">{{ time }}</div>
      <small class="text-muted">{{ message }} </small>
    </div>
    
  </div>
</template>


<style scoped>
.img-svg{
  background-color: "warning-gradient";
  
}
.title-text{
  font-size: 12px;
}
</style>

<script>



//let defaultColor  = "gradient-warning"; // TODO: Get from theme variables 
let defaultColor  = "var(--light)" //"#e5bf8b";
const DEFAULT_STROKE = "var(--info)"

const DEFAULT_IMG = "/images/lightning-bolt-100.png"

export default {
  name: "LatestActivity",
  props: {
    myData: Object, // Deprecated
    message: String,
    time: String,
    img: String,
    id: String,
    background: String,
  },
  data: function () {
    return {
      defaultColor: "var(--gray)",
      defaultStroke: DEFAULT_STROKE,
    };
  },
  mounted: function () {},
  computed: {
    myImg: function(){
      if(this.img){
        return img;
      }
      return this.defaultImg; 
    },
    defaultImg(){
      return DEFAULT_IMG;
    },
    color: function () {
      if (this.background) {
        return this.background;
      } else return this.defaultColor;
    },
    stroke(){
      return this.defaultStroke; 
    }
  },
};
</script>