


<template>
  
      <div>
      <div v-if="count>0" class="overflow-auto">

      <RecentActivityListItem v-for="item in list" v-bind:key="item.index" 
                    :message="item.message"
                    :time="toLocalTime(item.time)"
                    :id="item.myId"
                    :img="item.image"
                    :background="getColor(item)"
                                     >
          </RecentActivityListItem>
      </div>
      <div v-else class="text-center">
    
        <h2> No Recent Activity </h2>
      </div>
      </div>
   
  
</template>



<style scoped>
</style>


<script>

import RecentActivityListItem from "./RecentActivityListItem";

export default {
  name: "DeviceActivity",
  components: {

    RecentActivityListItem
  },
  props: {
    myList:Array,
  },

  data() {
    return {
      
      
    };
  },
  mounted(){
    
  },
  computed: {
    list: function(){
      return this.myList;
    },
    moment(){
      return this.$moment; // Moment API Wrapper 
    },
    count(){
      return this.list.length;
    }
  },
  methods: {
    toLocalTime(time){
        var myTime;
        try {
            myTime = this.moment.duration(Date.now()-new Date(time)).humanize();
            myTime += " ago";
        } catch (e) {
            console.log(e);
            if(time)
              myTime = this.time.toString();
        } finally {
          return myTime;
        }
      },
    getColor: function(item){
    
    if(item && item.background) 
      return item.background;
    else{

      // TODO: Check Color based on type 
      return  "var(--light)"; //'rgba(23, 138, 184,0.5)';
    }
  }
    }
  
};
</script>