<template>
    <CRow class="m-0 p-0">
    <CCol lg="12" >
      <CCard :accent-color="accentColor" >
          <slot name="header" />
        <CCardBody class="m-0 p-0" >
          <slot /> 
        </CCardBody>
        <CCardFooter class="m-0 p-0"> 
            <slot name="footer"/>
        </CCardFooter>
      </CCard>
    </CCol>
    <CElementCover
      v-show="cover"
      :boundaries="[{ sides: ['top', 'left'], query: '#media-body' }]"
      :opacity="0.1"
    >
    <slot name="cover">
      <h1 class="d-inline">{{coverMessage}} </h1>
      <CSpinner size="6xl" color="info" />
      </slot>
    </CElementCover>
    </CRow>
    
</template>


<script>


/** Defines a Default Layout that can be re-used for Pages 
 * To be Rendered in Router View
 */
// TODO: Import the thememable mixin here and we will make layout themeable

//import ThemableCardMixin from "@/components/mixins/ThemableCardMixin"; 

export default {
    name:"DefaultLayout", 
    props:{
        accentColor:{
            type: String,
            default: ""
        },
        title:{
            type: String, 
            default: "",
        },
        /** Define which CoreUI Icon to show */
        icon: {
            type: String, 
            default: "cil-file"
        },
        cover: { 
            /**Defines if Element Cover is Shown or not  */
            type: Boolean, 
            default: false, 
        }, 
        /** Override default message when element cover is shown
         * Note: You can also override this with the #cover slot 
         */
        coverMessage:{
            type: String,
            default: "Loading..."
        }

    },  
    data(){
        return{

        }
    },
    computed:{
        theme(){
            if(this.$theme && this.$theme.backColor){
                return this.$theme.backColor;
            }
            return "";
        }
    }, 
    created(){
        
    }
}
</script>