
<template>
  <!-- Creates a Widget that we can re-use for admin pages -->
  <CRow class="py-0 my-0">
    <CCol col="12" class="py-0 my-0">
      <DashboardCard :color="theme" :accent="accent" :collapsible="false" :collapsed.sync="collapsed"
        class="dashboard-header-card">
        <template #header>
          <span><CButton color="light"
          size="sm"
           variant="ghost" @click="handleInfoClick">
            <CIcon name="cil-info" href="" /></CButton> Truck: {{truck}} </span> 
          <span class="table-overflow-elipses ml-1 mt-0 "><small>
            <ConnectionStatusBadge :value="getOnlineState()">

            </ConnectionStatusBadge>
                    
                </small>
                </span>

        </template>
        <template #body>
          <div>
            <div class="no-gutters my-0 py-0 py-0">
              <CRow class="no-gutters my-0 py-0">
                <CCol col="3" class="pt-0 p-0 m-0">
                  <CRow>
                    <CCol>
                      <span class="cards-label card-text-light">Last Connected</span>
                  <span id="last-connected" class="cards-text">{{
                    showTime(lastConnected)
                  }}</span>
                    </CCol>

                  </CRow>

                </CCol>
                <CCol>
                    <CRow v-if="false">
                      <CCol>
                        <div v-if="info.radio">
                          <span class="cards-label card-text-light">Receiver Type</span>
                          <span class="cards-text">{{ info.radio }}</span>
                        </div>
                      </CCol>
                    </CRow>
                </CCol>
                <CCol class="alarm-container" >
                  <CRow class="mt-0 float-right" v-if="!loading">
                    <CCol>
                      <CRow col="12" class="m-0 p-0">
                      <span class="m-0 p-0 my-0" v-show="item.status">
                        <AlarmStatusBadge :item="item" :timestamp="item.timestamp"
                        :humanize="false">
                        </AlarmStatusBadge>
                        
                      </span>
                    </CRow>
                    <CRow class="p-0 m-0" col="12" v-if="item.status !='stopped' && getMessage(item)">
                      <div class="message text-center p-0 m-0">
                        <CBadge class="message text-center p-0 m-0">
                          <span>{{ getMessage(item) }}</span>
                        </CBadge>
                      </div>
                    </CRow>
                    <CRow col="12" class="message text-center p-0" v-else >
                      <span >
                        <span class="card-text-light">Last Alert: <span class="text-center">{{ getLastAlertTime()  }}</span></span>
                      </span>
                    </CRow>


                    </CCol>
    

 

                   
    

                   


                  </CRow>
                  <CRow v-else> 
                    <CKebabSpinner v-if="variant==='kebab'"></CKebabSpinner>
                    <CSpinner v-else size="6xl" color="info" /></CRow>
                </CCol>
              </CRow>

            </div>

          </div>


        </template>


      </DashboardCard>
    </CCol>
  </CRow>
</template>

<style scoped>
.dashboard-header-card {
  max-height: 200px;
  margin-top: 0px;
  /*background-color: var(--theme-hightlight); */
}


.some-gutters {
  margin-right: 0;
  margin-left: 0;
  margin-bottom: -2px;
  padding-right: 0;
  padding-left: 1;
  padding-bottom: 0px;
}

.alarm-container{
  margin-right: 0;
  margin-left: 0;
}
</style>

<script>

import IoTMetadata from "@/components/dashboard/IoTMetadata";
import DashboardCard from "@/components/dashboard/DashboardCard";
import TruckStatusCard from "@/views/app/TruckStatusCard.vue";
import AlarmStatusBadge from "@/components/badges/AlarmStatusBadge.vue";
import TruckInfoCard from "@/views/app/TruckInfoCard.vue";
import ConnectionStatusBadge from "@/components/badges/ConnectionStatusBadge";
import CKebabSpinner from "@/components/base/CKebabSpinner.vue";
//import {deviceInfo,radioInfo} from "../../data/appData";

/** Some Default Data for Debugging Purposes. */
const MAX_TIME_RANGE = 86400*1000*2;


export default {
  name: "DashboardHeaderSimple",
  components: {
    IoTMetadata,
    DashboardCard,
    TruckStatusCard,
    TruckInfoCard,
    AlarmStatusBadge,
    ConnectionStatusBadge,
    CKebabSpinner, 

  },
  props: {
    device: {
      type: Object,
    },
    info: {
      type: Object
      //default: defaultInfo,
    },
    theme: {
      type: String,
      default: "gradient-dark"
    },
    accent: {
      type: String,
      default: "danger",
    },
    loading: {
      type: Boolean,
      default: false,
    }, 
    alerts:{
      type: Array, 

    }
  },
  data() {
    return {
      collapsed: false,
      variant: "kebab",
    };
  },
  computed: {
    item() {
      return this.device;
    },
    truck(){
      return this.device.name; 
    },

    lastConnected: function () {
      if (this.info && this.info.status) {
        //return this.showTime(this.info.status.lastConnect);
        return this.info.status.lastConnect;
      }
      else return "";

    },
    connected(){
      return this.item.connected; 
    }, 
    lastAlert(){
      if(!this.alerts || this.alerts.length==0){
        return undefined; 
      }
      else{
        return this.alerts[0]; 
      }
    }

  },

  methods: {
    getOnlineState() {
            if (this.connected === "online") {
                return "online";
            } else {
                return "";
            }
        },
    getMessage(item) {
      return item.message ? item.message : "";
    },
    handleInfoClick(e){
      this.$emit("info-click", e); 
    }, 
    getLastAlertTime(){
        let temp = '' ; 
        let lastAlert = this.lastAlert; 
        let time = MAX_TIME_RANGE;  // Default time 
        if(lastAlert){
          
          time = Date.now() - lastAlert.timestamp; 
        }
        console.log(time); 
        temp = `Over ${this.$moment.duration(time, "ms").humanize()} ago`; 
        return temp; 
    }, 
    showTime(time) {
      if (!time) return "";
      var myTime;
      try {
        myTime = this.$moment.duration(Date.now() - new Date(time)).humanize();
        myTime += " ago";
      } catch (e) {
        console.log(e);
        if (time)
          myTime = time.toString();
      }
      return myTime;

    },
  }
};
</script>
