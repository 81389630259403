var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "media text-muted pt-3 message" }, [
    _c("div", { staticClass: "py-0 pb-0 mr-3 float-left rounded" }, [
      _c("div", { staticClass: "c-avatar" }, [
        _c(
          "svg",
          {
            staticClass: "bd-placeholder-img mr-2 rounded img-svg",
            attrs: {
              width: "32",
              height: "32",
              xmlns: "http://www.w3.org/2000/svg",
              preserveAspectRatio: "xMidYMid slice",
              focusable: "false",
              role: "'/' +img",
              "aria-label": "Placeholder: 32x32"
            }
          },
          [
            _c("title", [_vm._v(_vm._s(_vm.id))]),
            _c("rect", {
              staticClass: "rouded",
              staticStyle: { "stroke-width": "1" },
              attrs: {
                fill: _vm.color,
                stroke: _vm.defaultStroke,
                rx: "5",
                ry: "5",
                width: "100%",
                height: "100%"
              }
            }),
            _c("image", {
              staticClass: "rounded bd-placeholder-img mr-2",
              attrs: {
                href: _vm.myImg,
                alt: _vm.defaultImg,
                width: "100%",
                height: "100%"
              }
            })
          ]
        )
      ])
    ]),
    _c("div", { staticClass: "pb-3 mb-0 lh-125 border-bottom border-gray" }, [
      _c(
        "div",
        { staticClass: "d-block text-gray-dark font-weight-bold title-text" },
        [_vm._v(_vm._s(_vm.time))]
      ),
      _c("small", { staticClass: "text-muted" }, [
        _vm._v(_vm._s(_vm.message) + " ")
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }